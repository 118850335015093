<template>
      <span  class="document-actions">
          <i class="si si-bin" title="Delete" @click="deleteAction(id, $t('qtn_delete_ss'))"></i>
          <i class="si si-pen" title="Edit" @click="handleModal(name, id, notify)"></i>

          <SaiModal :display="showEditModal" @closeModal="showEditModal=false">
                <span slot="title">{{$t('frm_edit_saved_search')}}</span>
                <form slot="body" method="post" @submit.prevent="editAction" class="edit-save-search-modal-form">
                    <div class="sai-field">
                        <label>{{$t('idx_title')}}</label>
                        <input type="text"  name="name" maxlength="50" v-model="editModalName" required>
                    </div>
                    <div v-if="showNotify" class="sai-field notify">
                        <div>
                            <input type="checkbox" :placeholder="$t('frm_placeHolder_title')" 
                            name="save_search_notify" id="save_search_notify" v-model="editModalNotify">
                        </div>
                        <div>
                            <label for="save_search_notify">{{$t('frm_enable_alerts_saved_search')}}</label>
                        </div>
                    </div>
                    <div class="sai-form-buttons">
                        <button class="sai-btn-primary">{{$t('save')}}</button>
                        <a class="closeDialog" @click="showEditModal=false">{{$t('frm_cancel')}}</a>
                    </div>
                </form>
            </SaiModal>

      </span>
</template>

<script>
import SaiModal from "@/components/common/SaiModal";
import {createNamespacedHelpers} from 'vuex';
const {mapActions} = createNamespacedHelpers('quickviews');
export default {
    name: "EditSavedSearch",
    components: {
        SaiModal
    },
    data() {
      return {
          showEditModal: false,
          editModalId : null,
          editModalName : '',
          editModalNotify: 0
      }
    },
    props: {
      name:{ type:String, default: '' },
      id:{ type: Number, default: null },
      notify:{ type:Boolean, default: false },
      details: { type:String, default: '' },
    },
    computed: {
        showNotify() {
            const saveSearchDetails = JSON.parse(this.details);
            const searchTab = saveSearchDetails[0];
            if(searchTab == 'search.standards') {
                return true;
            }
            return false;
        }
    },
    methods: {
        ...mapActions([
                'updateSavedSearch',
                'fetchAccordions'
            ]
        ),
        async deleteAction(id, message) {
            if (confirm(message)) {
                const loader = this.$loading.show();
                const response = await this.updateSavedSearch({action : 'delete', elementId: id});
                if (response) {
                    loader.hide();
                }
            }
        },
        handleModal(name, id, notify) {
            this.showEditModal = true;
            this.editModalId = id;
            this.editModalName = name;
            this.editModalNotify = notify;
        },
        async editAction() {
            const loader = this.$loading.show();
            const notify = this.editModalNotify ? 1 : 0;
        
            const response = await this.updateSavedSearch({action : 'update', elementId: this.editModalId, elementName: this.editModalName, elementNotify: notify});
            if (response) {
                loader.hide();
            }
        }
    }
}
</script>

<style scoped>

</style>