<template>
    <div>
        <table class="accordion-columnar-table">
            <thead>
            <tr>
                <th v-for="header in headerContent" :key="header">
                    {{ $t(header) }}
                </th>
            </tr>
            </thead>
            <tbody>
                <tr v-for="content in bodyContent" :key="content.id">
                    <td v-for="(key,value) in content" :key="key" >
                        <span :class="key.status ? 'hasStatus' : ''" v-if="value==='name'">
                            <a :href="key.linkURL">{{key.linkName}}</a>
                            <span v-if="key.status">
                                <DocumentStatus :status="key.status"/>
                            </span>
                             <template v-if="key.actions">
                                <EditSavedSearch :name="key.linkName" :id="key.actions.id"  :notify="(key.notify.toString() == '1')" :details="key.details"/>
                             </template>
                        </span>
                        <span v-else v-html="key" @mouseenter="showTooltip" @mouseleave="hideTooltip"></span>
                        <span class="tooltip-container" v-html="key"></span>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="accordion-footer" v-if="footerContent">
            <a :href="footerContent.URL">
                {{ $t(footerContent.text) }}
            </a>
        </div>
    </div>
</template>

<script>
import EditSavedSearch from "@/components/QuickViews/EditSavedSearch";
import DocumentStatus from "@/components/QuickViews/DocumentStatus";
export default {
    name: "ColumnarContent",
    components: {
        DocumentStatus,
        EditSavedSearch
    },
    props: {
        bodyContent: { type: Array, default: () => [] },
        headerContent: {type: Array, default: () => [] },
        footerContent: {type: Object, default: () => {} },
        },
    methods: {
        showTooltip($event) {
            const parentElement = $event.target.closest('td');
            const tooltipElement = parentElement.querySelector('.tooltip-container');
            if (parentElement.offsetWidth < parentElement.scrollWidth) {
                tooltipElement.classList.add('show');
                tooltipElement.style.top = $event.clientY + "px";
            }
        },
        hideTooltip($event) {
            const parentElement = $event.target.closest('td');
            const tooltipElement = parentElement.querySelector('.tooltip-container');
            tooltipElement.classList.remove('show');
        },
    }
}
</script>
